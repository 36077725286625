import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _34db8d0e = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _428a3db5 = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _7695281f = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _a2232f78 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _92b7d026 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _768aacff = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0f7db529 = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _06bda2e4 = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _5aa25462 = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _56ce6fe4 = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _1a96f30c = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _fcfba5ec = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _0d8e0d84 = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _8a60ef1e = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _6a9c7532 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _a5b36dd2 = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _a639ec30 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _34db8d0e,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _428a3db5,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _7695281f,
    name: "callback"
  }, {
    path: "/downloads",
    component: _a2232f78,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _92b7d026,
    name: "guests-details"
  }, {
    path: "/login",
    component: _768aacff,
    name: "login"
  }, {
    path: "/maintenance",
    component: _0f7db529,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _06bda2e4,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _5aa25462,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _56ce6fe4,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _1a96f30c,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _fcfba5ec,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _0d8e0d84,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _8a60ef1e,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _6a9c7532,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _a5b36dd2,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _a639ec30,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
